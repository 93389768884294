import React, { useState, useEffect, useRef } from 'react'

import { getData, postData } from 'utils/server'
import NotificationAlert from 'react-notification-alert'
import { notificationOptions, getAuthorizationHeader } from 'utils/helpers'
// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

import csvDownload from 'json-to-csv-export'
import { update } from 'lodash'

export default function MarketsCategories({ autoload, collapsed }) {
  const controller = new AbortController()

  const notificationAlertRef = useRef(null)

  const [disabled, setDisabled] = useState(false)
  const [firstIcon, setFirstIcon] = useState(<i className="fas fa-file-csv"></i>)
  const [firstText, setFirstText] = useState(<span>Export Market Categories</span>)

  const resetButtonStateInitialMarketCategories = () => {
    setFirstIcon(<i className="fas fa-file-csv"></i>)
    setFirstText(<span>Export Market Categories</span>)
    setDisabled(false)
  }

  const fetchData = async (event, controller) => {
    setDisabled(true)
    setFirstIcon(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setFirstText(<span className="text-primary">Please wait...</span>)

    let response = await getData('/api/reports/markets/categories', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })

    if (response != null) {
      if (response.data.length != 0) {
        console.log(response.data)

        const dataToCSV = {
          data: response.data,
          filename: `SportUniCategory`,
          delimiter: ';',
          headers: ['Sport', 'UniName', 'Category']
        }
        csvDownload(dataToCSV)
        notificationAlertRef.current.notificationAlert(notificationOptions('Your file created successfully', 'success'))
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions('There are no data available for these options', 'danger'))
        return false
      }
    } else {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
      return
    }
    resetButtonStateInitialMarketCategories()
  }

  const [btnUpdateMarketCatDB_disabled, setDisabledBtnUpdateMarketCatDB] = useState(false)
  const [btnUpdateMarketCatDB_Icon, setIconBtnUpdateMarketCatDB] = useState(<i className="fas fa-sync-alt"></i>)
  const [btnUpdateMarketCatDB_Text, setTextBtnUpdateMarketCatDB] = useState(<span>Update Market Categories in DB</span>)

  const resetButtonStateInitialUpdateMarketCategoriesInDB = () => {
    setIconBtnUpdateMarketCatDB(<i className="fas fa-sync-alt"></i>)
    setTextBtnUpdateMarketCatDB(<span>Update Market Categories in DB</span>)
    setDisabledBtnUpdateMarketCatDB(false)
  }

  const updateMarketCategoriesInDB = async controller => {
    setDisabledBtnUpdateMarketCatDB(true)
    setIconBtnUpdateMarketCatDB(<i className="fas fa-spinner fa-spin text-primary"></i>)
    setTextBtnUpdateMarketCatDB(<span className="text-primary">Updating Market Categories in DB...</span>)

    try {
      const response = await postData('/api/reports/markets/update-categories', {}, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
      const responseMessage = response?.data ? response.data : 'Response message from server is not available'
      if (response.status === 200) {
        notificationAlertRef.current.notificationAlert(notificationOptions(responseMessage, 'success'))
      } else {
        notificationAlertRef.current.notificationAlert(notificationOptions(responseMessage, 'danger'))
      }
    } catch (error) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Something went wrong', 'danger'))
    } finally {
      resetButtonStateInitialUpdateMarketCategoriesInDB()
    }
  }

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <Card>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Card.Header>
        <Card.Title as="h4">Market Categories</Card.Title>
      </Card.Header>
      <Card.Body></Card.Body>
      <Card.Footer>
        <hr></hr>
        <Col md="4">
          <div className="mt-2">
            <Button variant="primary" onClick={e => fetchData(e, controller)} disabled={disabled}>
              {firstIcon} {firstText}
            </Button>
          </div>
        </Col>
        <Col md="4">
          <div className="mt-2">
            <Button variant="info" onClick={e => updateMarketCategoriesInDB(controller)} disabled={btnUpdateMarketCatDB_disabled}>
              {btnUpdateMarketCatDB_Icon} {btnUpdateMarketCatDB_Text}
            </Button>
          </div>
        </Col>
      </Card.Footer>
    </Card>
  )
}
