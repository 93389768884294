import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Card, Container, Form, Row, Col, Button, InputGroup, Alert } from 'react-bootstrap'
import Swal from 'sweetalert2'

import SendCoverageLost from 'components/Modals/SendCoverageLost'
import { postData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

const MAX_FILE_SIZE_MB = 1000

const convertToMegabyte = byteSize => (byteSize / (1024 * 1024)).toFixed(2)
const convertToByte = mbSize => mbSize * 1024 * 1024

function Operation() {
  const [showCoverageLostModal, setCoverageLostModal] = useState(false)
  const [text, setText] = useState('')
  const [getEventId, setEventId] = useState(null)
  const [CoverageLost, setCoverageLost] = useState(false)

  const [file, setFile] = useState(null)
  const [fileContent, setFileContent] = useState('')
  const [error, setError] = useState('')
  const [isSending, setSending] = useState(false)

  const handleSendCoverageLost = () => {
    setEventId(document.getElementById('eventId').value)
    setCoverageLostModal(true)
  }

  const handleCloseModal = cl => {
    setCoverageLostModal(false)
    if (cl) {
      CoverageLost == true ? setCoverageLost(false) : setCoverageLost(true)
    }
    setText('')
    setEventId(null)
  }

  const autoComplete = e => {
    e.preventDefault()
    setText(e.target.value)
  }

  const uploadFile = file => {
    const fileSizeMB = convertToMegabyte(file.size)

    if (fileSizeMB > MAX_FILE_SIZE_MB) {
      setError(`File size exceeds ${MAX_FILE_SIZE_MB}MB limit!`)
      return
    }

    setFile(file)
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      setFileContent(reader.result)
      setError('')
    }

    reader.onerror = () => setError('Error reading file. Please try again.')
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setError('')
    if (rejectedFiles.length > 0) {
      return
    }

    uploadFile(acceptedFiles[0])
  }, [])

  const onDropRejected = rejectedFiles => {
    const errors = rejectedFiles.flatMap(fileData => fileData.errors.map(error => error.message))
    const errorMessage = _.uniq(errors).join('. ')
    setError(errorMessage)
  }

  const handleFileChange = event => {
    const [changedFile] = event.target.files
    if (!changedFile) return
    setError('')
    uploadFile(changedFile)
  }

  const removeFile = () => {
    setFileContent('')
    setFile(null)
    setError('')
  }

  const sendBetradarMarkets = data => {
    Swal.fire({
      title: 'Sending Markets',
      text: 'Please wait while the data is being sent...',
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading()
    })

    setSending(true)

    const errorHandler = error => {
      const errorMessage = _.get(error, 'response.data', 'Internal server error.')
      Swal.fire({
        icon: 'error',
        title: 'Failed to send markets',
        text: 'Error: ' + errorMessage,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-danger'
        }
      })
      setSending(false)
    }

    postData('/api/markets/uploadBetradarMarkets', data, { 'Content-Type': 'application/xml', Authorization: getAuthorizationHeader() }, errorHandler).then(resp => {
      if (!_.isNull(resp)) {
        Swal.fire({
          icon: 'success',
          title: 'Markets sent successfully!',
          timer: 2000,
          showConfirmButton: false
        })
        removeFile()
        setSending(false)
      }
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, onDropRejected, multiple: false, accept: { 'text/xml': ['.xml'] }, maxSize: convertToByte(MAX_FILE_SIZE_MB) })

  return (
    <>
      <Container fluid>
        <SendCoverageLost showModal={showCoverageLostModal} eventId={getEventId} signalCloseModal={handleCloseModal} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="12">
                    <Card.Title as="h4">Send Coverage Lost</Card.Title>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="1">
                    <InputGroup.Text>Event Ids:</InputGroup.Text>
                  </Col>
                  <Col md="8">
                    <Form.Control id="eventId" name="eventId" type="text" onChange={autoComplete} value={text} placeholder="Comma seperated for more than one (ex. 123456,342342)"></Form.Control>
                  </Col>
                  <Col md="3">
                    <Button className="coverage-lost-btn" variant="success" disabled={!text} onClick={handleSendCoverageLost}>
                      Send
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="12">
                    <Card.Title as="h4">Upload Betradar Markets</Card.Title>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="1">
                    <InputGroup.Text>Upload file:</InputGroup.Text>
                  </Col>
                  <Col md="8">
                    {file ? (
                      <div className="d-flex align-items-center">
                        <span>
                          {file.name} - {convertToMegabyte(file.size)} MB
                        </span>
                        <Button variant="link" className="top-0 end-0 p-1 text-danger border-0" onClick={removeFile} style={{ textDecoration: 'none' }} disabled={isSending}>
                          ✖
                        </Button>
                      </div>
                    ) : (
                      <div {...getRootProps({ className: 'dropzone' })} className={`border p-5 text-center rounded ${isDragActive ? 'border-secondary' : ''}`}>
                        <input {...getInputProps()} onChange={handleFileChange} />
                        <p className="m-0">Drag & drop a file here, or click to select one</p>
                      </div>
                    )}
                    {error && (
                      <Alert variant="danger" className="mt-2">
                        {error}
                      </Alert>
                    )}
                    {!file && <label className="fw-bold">Upload XML File (only .xml). Max file size {MAX_FILE_SIZE_MB}MB</label>}
                  </Col>
                  <Col md="3">
                    <Button variant="success" disabled={fileContent.length === 0 || error.length > 0 || isSending} onClick={() => sendBetradarMarkets(fileContent)}>
                      Send
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Operation
